import React, { useEffect, useState } from 'react';
import { Tab, TabList } from '@fluentui/react-components';
import { ReactComponent as BackIcon } from 'assets/images/back-icon.svg';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useSendFeedbackData } from './SendFeedback/useSendFeedbackData';
import { ConfirmModal } from 'components/ConfirmModal';
import CustomLoader from 'components/CustomLoader';
import SendFeedback from './SendFeedback';
import RequestFeedback from './RequestFeedback';
import './styles.scss';

const FeedbackSelection = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const token = searchParams.get('token') || location.state?.token;
    const [loading, setLoading] = useState(true);
    const data = useSendFeedbackData(token, setLoading);
    const [isTab, setIsTab] = useState(false);
    const [selectedTab, setSelectedTab] = useState('send');
    const [showNoManagerModal, setShowNoManagerModal] = useState(false);
    const NO_MANAGER_MODAL_DATA = {
        confirm: 'Close',
        heading: `Cannot Send/Request feedback`,
        subHeading: `You have no manager assigned yet, you can give/request feedback only if a manager is assigned.</br></br>Please contact your admin for more details- <b>${data?.adminsList}</b>`,
    };

    const onTabSelect = (_event, data) => {
        setSelectedTab(data.value);
    };

    useEffect(() => {
        if (data) {
            setShowNoManagerModal(!data.isAllowedFeedback);
        }
    }, [data]);

    return (
        <div className='feedback-container'>
            <CustomLoader active={loading}>
                {!showNoManagerModal ? (
                    <>
                        {isTab && (
                            <div className='back-navigation'>
                                <span onClick={() => navigate(-1)}>
                                    <BackIcon /> Back
                                </span>
                            </div>
                        )}
                        <TabList
                            selectedValue={selectedTab}
                            onTabSelect={onTabSelect}
                            defaultSelectedValue='send'
                            className='tab-buttons'
                        >
                            <Tab className='tab-label' value='send'>
                                Send Feedback
                            </Tab>
                            <Tab className='tab-label' value='receive'>
                                Request Feedback
                            </Tab>
                        </TabList>

                        {selectedTab === 'send' ? (
                            <SendFeedback setIsTab={setIsTab} />
                        ) : (
                            <RequestFeedback setIsTab={setIsTab} />
                        )}
                    </>
                ) : (
                    <ConfirmModal data={NO_MANAGER_MODAL_DATA} />
                )}
            </CustomLoader>
        </div>
    );
};

export default FeedbackSelection;
