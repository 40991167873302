export const WHATS_ON_YOUR_MIND = "What's on your mind today?";
export const RECOGNIZE_SOMEONE = 'Recognize Someone';
export const RECOGNIZE_SOMEONE_SUBTEXT = 'Celebrate a win - big or small - and appreciate someone today';
export const REDEEM = 'Redeem Points';
export const FEEDBACK = '360º Feedback';
export const VIEW_IN_WEB = '(View in web)';
export const REDEEM_SUBTEXT = 'You can redeem up to ';
export const AWARD_BONUS = 'Award Bonus';
export const AWARD_BONUS_SUBTEXT = 'Award employees on special occasions';
export const FEEDBACK_SUBTEXT = 'Give or receive 360° feedback from your peers.';
export const RECOGNITION_SETUP_DONE_ADMIN =
    'Please set up Payments and country for points conversion. Clicking on setup will take you to dashboard.';
export const RECOGNITION_SETUP_DONE_USER = 'Please contact your admin to set up Rewards and Recognitions.';
export const REWARDS_AND_RECOGNITION = 'Rewards and Recognition';
export const GO_TO_SETTINGS = 'Go to settings';
export const SEEK_HELP = 'Seek help or report an issue at ';
export const EMPLOYEE_AWARDS = 'Employee Awards';
export const EMPLOYEE_AWARDS_SUBTEXT = 'Award employees directly or with company-wide polls';
export const SEND_SUGGESTION = 'Submit suggestion';
export const SEND_SUGGESTION_SUBTEXT = 'Start a discussion on policies, culture,  leadership and more.';
