import { useState, useRef } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getRecognitionData } from 'Services/apiFunctions';
import { GET_RECOGNITION_DATA } from 'Services/apiKeys';
import { partition } from 'lodash';
import { capitalizeFLetter } from 'utils/helperFunctions';
import { RESTRICTED_USER_NOTE } from './constant';

const transformRecognitionData = (recognitionData, messageId, setInitialValues, initialValues) => {
    const { rewardTypes, users, companyValues, channels, preFilledData, creditBalance, defaultRecognitionChannel } =
        recognitionData;

    const rewardList = rewardTypes
        .filter((option) => option._id && option.name) // Filter out invalid reward types
        .map((option) => ({
            header: `${capitalizeFLetter(option.name)} (${option.points} Points)`,
            id: option._id,
            points: option.points,
        }));

    const memberList = users
        .filter((member) => member._id && member.userName) // Filter out users missing id or userName
        .map((member) => {
            const isMemberDisabled = member.permissions?.recognition?.restricted;
            return {
                header: member.userName,
                disabled: isMemberDisabled,
                disabledNote: RESTRICTED_USER_NOTE,
                id: member._id,
            };
        });

    const [enabledMembers, disabledMembers] = partition(memberList, (member) => !member.disabled);
    const sortedMemberList = [...enabledMembers, ...disabledMembers];

    const valueList = companyValues
        .filter((option) => option._id && option.valueStr) // Filter out invalid company values
        .map((option) => ({
            header: option.valueStr,
            id: option._id,
            disabled: false,
        }));

    const channelList = channels
        .filter((option) => option.groupName && option.channelName) // Filter out invalid channels
        .map((option) => ({
            header: `${option.groupName} -> ${option.channelName}`,
            ...option,
        }));

    if (messageId && preFilledData) {
        const selectedUsers = preFilledData.users.map((user) => ({
            header: user.userName,
            id: user._id,
        }));

        const selectedReward = preFilledData.rewardType
            ? {
                  header: `${capitalizeFLetter(preFilledData.rewardType.name)} (${
                      preFilledData.rewardType.points
                  } Points)`,
                  id: preFilledData.rewardType._id,
              }
            : '';

        setInitialValues({
            ...initialValues,
            recognitionType: selectedReward,
            recognitionRecipients: selectedUsers,
        });
    } else {
        setInitialValues({
            ...initialValues,
            recognitionType: rewardList[0],
            recognitionChannel: defaultRecognitionChannel || '',
        });
    }
    return {
        rewards: { data: rewardList, isFetching: false },
        members: { data: sortedMemberList, isFetching: false },
        coreValues: { data: valueList, isFetching: false },
        channel: { data: channelList, isFetching: false },
        creditBalance,
        shouldShowRecognitionChannel: !(messageId || defaultRecognitionChannel),
    };
};

export const useRecognitionData = (authToken, messageId, setCreditBalance) => {
    const navigate = useNavigate();
    const [dropdownData, setDropdownData] = useState({
        rewards: { data: [], isFetching: true },
        members: { data: [], isFetching: true },
        coreValues: { data: [], isFetching: true },
        channel: { data: [], isFetching: true },
    });
    const [initialValues, setInitialValues] = useState({
        recognitionType: {},
        recognitionRecipients: [],
        recognitionReason: '',
        companyValue: [],
        recognitionChannel: {},
    });
    const shouldShowRecognitionChannel = useRef();

    const { data: recognitionData, isFetching: recognitionDataLoading } = useQuery(
        [GET_RECOGNITION_DATA, authToken, messageId],
        getRecognitionData,
        {
            onError: () => {
                navigate('/modals/inactive');
            },
            onSuccess: (data) => {
                const recognitionData = data.data;
                const transformedData = transformRecognitionData(
                    recognitionData,
                    messageId,
                    setInitialValues,
                    initialValues
                );

                setCreditBalance(transformedData.creditBalance);
                shouldShowRecognitionChannel.current = transformedData.shouldShowRecognitionChannel;

                setDropdownData({
                    rewards: transformedData.rewards,
                    members: transformedData.members,
                    coreValues: transformedData.coreValues,
                    channel: transformedData.channel,
                });
            },
        }
    );

    return {
        ...dropdownData,
        initialValues,
        currentUserId: recognitionData?.data.userId,
        isCompanyValueRequired: recognitionData?.data.isCompanyValueRequired,
        defaultRecognitionChannel: recognitionData?.data.defaultRecognitionChannel,
        shouldShowRecognitionChannel: shouldShowRecognitionChannel.current,
        recognitionDataLoading: recognitionDataLoading,
        isAiEnabled: recognitionData?.data.isAiEnabled,
        allowRecognitionMediaSelection: recognitionData?.data.allowRecognitionMediaSelection ?? true,
        maxValues: recognitionData?.data.maxValues ?? 3,
    };
};
