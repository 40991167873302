import * as Yup from 'yup';
import Pluralize from 'pluralize';

export const FORM_FIELDS = {
    recognitionType: {
        name: 'recognitionType',
        label: 'Type of recognition',
        placeholder: 'Select',
    },
    recognitionRecipients: {
        name: 'recognitionRecipients',
        label: 'Who do you want to recognize?',
        placeholder: 'Type or select someone',
    },
    recognitionReason: {
        name: 'recognitionReason',
        label: 'Why do you want to recognize?',
        placeholder: 'Write Something',
    },
    companyValue: {
        name: 'companyValue',
        label: 'Company value',
        placeholder: 'Select',
    },
    recognitionChannel: {
        name: 'recognitionChannel',
        label: 'Where do you want to post your recognition?',
        placeholder: 'Select',
    },
};

export const RESTRICTED_USER_NOTE = 'This user has been restricted from receiving recognitions by the admin.';

export const getValidationSchema = (isCompanyValueRequired, messageId, currentUserId, creditBalance, maxValues = 3) =>
    Yup.object().shape({
        recognitionRecipients: Yup.array()
            .min(1, 'At least one recipient is required')
            .test({
                message: 'You cannot recognize yourself',
                test: function (value) {
                    return value.every((recipient) => recipient.id !== currentUserId);
                },
            })
            .test({
                name: 'sufficientBalance',
                message: 'Insufficient balance',
                test: function (value) {
                    const recognitionType = this.parent.recognitionType;
                    if (recognitionType && recognitionType.points) {
                        return recognitionType.points * value.length <= creditBalance;
                    }
                    return true;
                },
            }),
        recognitionReason: messageId
            ? Yup.string().max(3000, 'Recognition Reason must be less than or equal to 3000 characters')
            : Yup.string()
                  .required('Recognition Reason is required')
                  .max(3000, 'Recognition Reason must be less than or equal to 3000 characters'),
        companyValue: isCompanyValueRequired
            ? Yup.array()
                  .min(1, 'Atleast One Company Value is required')
                  .max(maxValues, `Only ${Pluralize('value', maxValues, true)} can be added.`)
            : Yup.array().max(maxValues, `Only ${Pluralize('value', maxValues, true)} can be added.`),
        recognitionChannel: Yup.object().required('Recognition Channel is required'),
        recognitionType: Yup.object().required('Recognition type is required'),
    });
