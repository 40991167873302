import React from 'react';
import { Button, Spinner, typographyStyles, makeStyles } from '@fluentui/react-components';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    text: typographyStyles.body1,
});

export const EWButton = ({ appearance, buttonText, position = 'right', disabled = false, marginRight, onClick }) => {
    const { isSubmitting, handleSubmit } = onClick ? {} : useFormikContext();
    const styles = useStyles();
    const buttonIcon = isSubmitting ? <Spinner size='tiny' /> : null;

    const containerStyle = {
        textAlign: position,
        marginTop: '20px',
        marginBottom: '10px',
        marginRight: marginRight,
    };

    return (
        <div style={containerStyle}>
            <Button
                appearance={appearance}
                className={styles.text}
                disabled={isSubmitting || disabled}
                icon={buttonIcon}
                onClick={onClick || handleSubmit}
            >
                {buttonText}
            </Button>
        </div>
    );
};
EWButton.propTypes = {
    appearance: PropTypes.string,
    buttonText: PropTypes.string,
    position: PropTypes.string,
    disabled: PropTypes.bool,
    marginRight: PropTypes.string,
    onClick: PropTypes.func,
};
