import { useState } from 'react';
import { useQuery } from 'react-query';
import { getRequestFeedbackData } from 'Services/apiFunctions';
import { showSnackBarMessage } from 'utils/helperFunctions';
import { useToaster } from 'context/SnackbarContext';
import { useNavigate } from 'react-router-dom';
import { GET_REQUESt_FEEDBACK_DATA } from 'Services/apiKeys';

const transformRequestFeedbackData = (requestFeedbackData) => {
    const { users } = requestFeedbackData;

    const usersList = users
        .filter((member) => member._id && member.userName)
        .map((member) => {
            return {
                header: member.userName,
                id: member._id,
            };
        });

    return {
        users: { data: usersList, isFetching: false },
    };
};

export const useRequestFeedbackData = (authToken, setLoading) => {
    const navigate = useNavigate();
    const { setSnackbar } = useToaster();
    const [dropdownData, setDropdownData] = useState({
        users: { data: [], isFetching: true },
    });

    const { data: feedbackData } = useQuery([GET_REQUESt_FEEDBACK_DATA, authToken], getRequestFeedbackData, {
        onSuccess: (data) => {
            const feedbackData = data.data;
            const transformedData = transformRequestFeedbackData(feedbackData);
            setDropdownData({
                users: transformedData.users,
            });
            setLoading(false);
        },
        onError: (err) => {
            showSnackBarMessage(setSnackbar, 'error', err?.message);
            navigate('/modals/inactive');
        },
    });

    return {
        ...dropdownData,
        currentUserId: feedbackData?.data.currentUserId,
        isAllowedFeedback: feedbackData?.data.isAllowedFeedback,
        adminsList: feedbackData?.data.adminsList?.join(','),
    };
};
