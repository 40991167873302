import { useQuery } from 'react-query';
import { getSendFeedbackData } from 'Services/apiFunctions';
import { showSnackBarMessage } from 'utils/helperFunctions';
import { useToaster } from 'context/SnackbarContext';
import { useNavigate } from 'react-router-dom';
import { GET_SEND_FEEDBACK_DATA } from 'Services/apiKeys';
import { useState } from 'react';

const transformSendFeedbackData = (sendFeedbackData) => {
    const { users, category } = sendFeedbackData;

    const usersList = users
        .filter((member) => member._id && member.userName)
        .map((member) => {
            return {
                header: member.userName,
                id: member._id,
            };
        });

    const categoryList = category.map((cat) => {
        return { header: cat };
    });

    return {
        users: { data: usersList, isFetching: false },
        category: { data: categoryList, isFetching: false },
    };
};

export const useSendFeedbackData = (authToken, setLoading) => {
    const navigate = useNavigate();
    const { setSnackbar } = useToaster();
    const [dropdownData, setDropdownData] = useState({
        users: { data: [], isFetching: true },
        category: { data: [], isFetching: true },
    });

    const { data: feedbackData } = useQuery([GET_SEND_FEEDBACK_DATA, authToken], getSendFeedbackData, {
        onSuccess: (data) => {
            const feedbackData = data.data;
            const transformedData = transformSendFeedbackData(feedbackData);
            setDropdownData({
                users: transformedData.users,
                category: transformedData.category,
            });
            setLoading(false);
        },
        onError: (err) => {
            showSnackBarMessage(setSnackbar, 'error', err?.message);
            navigate('/modals/inactive');
        },
    });

    return {
        ...dropdownData,
        allowAnonymous: feedbackData?.data.allowAnonymous,
        isAiEnabled: feedbackData?.data.isAiEnabled,
        isAllowedFeedback: feedbackData?.data.isAllowedFeedback,
        adminsList: feedbackData?.data.adminsList?.join(', '),
        currentUserId: feedbackData?.data.userId,
    };
};
