import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { EWButton } from 'components/EWButton';
import './style.scss';

export const ConfirmModal = ({ data, hideBtn }) => {
    const navigate = useNavigate();

    return (
        <div className='confirm-container'>
            <h3>{data?.heading}</h3>
            {data?.subHeading && <p dangerouslySetInnerHTML={{ __html: data.subHeading }}></p>}
            {!hideBtn && (
                <EWButton appearance='primary' buttonText={data?.cancel || 'Cancel'} onClick={() => navigate(-1)} />
            )}
        </div>
    );
};

ConfirmModal.propTypes = {
    data: PropTypes.object,
    hideBtn: PropTypes.bool,
};
