import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useLocation, useSearchParams } from 'react-router-dom';
import CustomLoader from 'components/CustomLoader';
import RequestFeedbackForm from './requestFeedbackForm';
import { useRequestFeedbackData } from './useRequestFeedbackdata';
import { ConfirmModal } from 'components/ConfirmModal';

const Index = ({ setIsTab }) => {
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const isTab = location.state?.token;
    const token = searchParams.get('token') || location.state?.token;
    const [loading, setLoading] = useState(true);
    const [showNoManagerModal, setShowNoManagerModal] = useState(false);
    const data = useRequestFeedbackData(token, setLoading);

    const NO_MANAGER_MODAL_DATA = {
        confirm: 'Close',
        heading: `Cannot Send/Request feedback`,
        subHeading: `You have no manager assigned yet, you can give/request feedback only if a manager is assigned.</br></br>Please contact your admin for more details- <b>${data?.adminsList}</b>`,
    };

    useEffect(() => {
        if (data) {
            setShowNoManagerModal(!data?.isAllowedFeedback);
        }
    }, [data]);

    console.log(data);
    if (isTab) {
        setIsTab(true);
    }

    return (
        <CustomLoader active={loading}>
            {showNoManagerModal ? (
                <ConfirmModal data={NO_MANAGER_MODAL_DATA} hideBtn />
            ) : (
                <div className={clsx({ 'feedback-container': !isTab })}>
                    <div className='headline-2'>Request 360° Feedback</div>
                    <RequestFeedbackForm setSubmitLoader={setLoading} data={data} />
                </div>
            )}
        </CustomLoader>
    );
};

Index.propTypes = {
    setIsTab: PropTypes.func,
};

Index.defaultProps = {
    setIsTab: () => {},
};

export default Index;
