import * as Yup from 'yup';

export const FORM_FIELDS = {
    selectedUser: {
        name: 'selectedUser',
        label: 'Who is this feedback for?',
        placeholder: 'Select User',
    },
    strength: {
        name: 'strength',
        placeholder: 'Select an option',
    },
    improvement: {
        name: 'improvement',
        placeholder: 'Select an option',
    },
    feedbackMessage: {
        name: 'feedbackMessage',
        label: 'Feedback Message',
        placeholder: 'Please share feedback on what worked and what would have been done differently.',
    },
    anonymousConfig: {
        name: 'anonymousConfig',
        label: 'Anonymously',
        subtitle: 'Your feedback will be shared anonymously',
    },
    feedbackFormTitle: 'Share constructive and actionable feedback with your co-workers',
};

export const initialValues = {
    selectedUser: {},
    strength: [],
    improvement: [],
    feedbackMessage: '',
    anonymousConfig: false,
};

export const getValidationSchema = (currentUserId, values) =>
    Yup.object().shape({
        selectedUser: Yup.mixed()
            .test(
                'is-not-empty',
                'Please select a user to give a feedback to',
                (value) => value && Object.keys(value).length > 0
            )
            .required('Please select a user to give a feedback to')
            .test({
                message: 'You cannot send feedback to yourself',
                test: function (value) {
                    return value.id !== currentUserId;
                },
            }),
        feedbackMessage: Yup.string()
            .required('Feedback message is required.')
            .max(3000, 'Feedback message must be less than or equal to 3000 characters.'),
        strength: Yup.array().test({
            message: 'Values in strength and improvement cannot be same.',
            test: function (value) {
                return value.every((ele) => {
                    return !values.improvement.includes(ele);
                });
            },
        }),
        improvement: Yup.array().test({
            message: 'Values in strength and improvement cannot be same.',
            test: function (value) {
                return value.every((ele) => {
                    return !values.strength.includes(ele);
                });
            },
        }),
    });
