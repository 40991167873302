import * as Yup from 'yup';

export const FORM_FIELDS = {
    selectedUser: {
        name: 'selectedUser',
        label: 'Select whom to request',
        placeholder: 'Select a User',
    },
    requestMessage: {
        name: 'requestMessage',
        placeholder: 'Enter your message here',
    },
    feedbackFormTitle: 'Request feedback from your co-workers',
};

export const initialValues = {
    selectedUser: [],
    requestMessage: '',
};

export const getValidationSchema = (currentUserId) =>
    Yup.object().shape({
        selectedUser: Yup.array()
            .min(1, 'At least one recipient is required')
            .test({
                message: 'You cannot request feedback from yourself',
                test: function (value) {
                    return value.every((recipient) => recipient.id !== currentUserId);
                },
            }),
        requestMessage: Yup.string().max(3000, 'Feedback message must be less than or equal to 3000 characters.'),
    });
