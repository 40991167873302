import React from 'react';
import { Spinner, makeStyles } from '@fluentui/react-components';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    container: {
        position: 'relative',
        height: '100vh',
    },
    loader: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
});

const Index = ({ active, children }) => {
    const styles = useStyles();

    return (
        <div className={styles.container}>
            {active ? (
                <div className={styles.loader}>
                    <Spinner />
                </div>
            ) : (
                children
            )}
        </div>
    );
};

Index.propTypes = {
    active: PropTypes.bool,
    children: PropTypes.element,
};

export default Index;
