import React from 'react';
import { Formik, Form } from 'formik';
import { FORM_FIELDS, getValidationSchema, initialValues } from './constants';
import { CustomSingleSelectDropdown } from 'components/CustomSingleSelectDropdown';
import { CustomTextArea } from 'components/CustomTextArea';
import AIEnhanceButton from 'components/AIEnhanceButton';
import { dialog } from '@microsoft/teams-js';
import { Body1Stronger, Caption1, Checkbox } from '@fluentui/react-components';
import { EWButton } from 'components/EWButton';
import { CustomMultiSelectDropdown } from 'components/CustomMultiSelectDropdown';
import PropTypes from 'prop-types';

const SendFeedbackForm = ({ authToken, setSubmitLoader, data }) => {
    const { users, category, allowAnonymous, isAiEnabled, currentUserId } = data;

    const handleSubmit = (values) => {
        let data = {
            selectedUser: values?.selectedUser.id,
            strength: values?.strength.map((value) => value.header),
            improvement: values?.improvement.map((value) => value.header),
            anonymous: values?.anonymousConfig,
            message: values?.feedbackMessage,
        };

        setSubmitLoader(true);
        dialog.url.submit(
            { data: data, startedVia: 'EW Shortcuts messaging extension', flagType: 'feedback_sendFeedbackSubmit' },
            process.env.REACT_APP_TEAMS_APP_ID
        );
    };

    return (
        <Formik
            initialValues={initialValues}
            validate={(values) => {
                const schema = getValidationSchema(currentUserId, values);
                if (!schema) {
                    return {};
                }
                try {
                    schema.validateSync(values, { abortEarly: false });
                    return {};
                } catch (error) {
                    return (error.inner || []).reduce((errors, err) => {
                        errors[err.path] = err.message;
                        return errors;
                    }, {});
                }
            }}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({ values, setFieldValue }) => (
                <Form className='send-feedback-form'>
                    <div className='feedback-title'>{FORM_FIELDS.feedbackFormTitle}</div>
                    <CustomSingleSelectDropdown
                        {...FORM_FIELDS.selectedUser}
                        dropdownOption={users?.data}
                        isDropdownOptionLoading={users.isFetching}
                        onChange={(value) => setFieldValue('selectedUser', value)}
                    />
                    {category?.data.length ? (
                        <>
                            <CustomMultiSelectDropdown
                                {...FORM_FIELDS.strength}
                                label={
                                    <>
                                        What are their strengths 💪? <Caption1>(Optional)</Caption1>
                                    </>
                                }
                                dropdownOption={category?.data}
                                isDropdownOptionLoading={category.isFetching}
                            />
                            <CustomMultiSelectDropdown
                                {...FORM_FIELDS.improvement}
                                label={
                                    <>
                                        Where can they improve 🎯? <Caption1>(Optional)</Caption1>
                                    </>
                                }
                                dropdownOption={category?.data}
                                isDropdownOptionLoading={category.isFetching}
                            />
                        </>
                    ) : null}
                    <CustomTextArea
                        {...FORM_FIELDS.feedbackMessage}
                        label={
                            <div className='text-area-label'>
                                {FORM_FIELDS.feedbackMessage.label}
                                {isAiEnabled && (
                                    <AIEnhanceButton
                                        reason={values.feedbackMessage}
                                        setReason={(reason) => setFieldValue('feedbackMessage', reason)}
                                        authToken={authToken}
                                    />
                                )}
                            </div>
                        }
                    />
                    {allowAnonymous && (
                        <div className='feedback-config'>
                            <Body1Stronger>
                                Configurations <Caption1>(Optional)</Caption1>
                            </Body1Stronger>
                            <Checkbox
                                label={
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span>{FORM_FIELDS.anonymousConfig.label}</span>
                                        <span style={{ fontSize: 'small', color: 'gray' }}>
                                            {FORM_FIELDS.anonymousConfig.subtitle}
                                        </span>
                                    </div>
                                }
                                checked={values.anonymousConfig}
                                onChange={(e) => setFieldValue('anonymousConfig', e.target.checked)}
                            />
                        </div>
                    )}
                    <EWButton appearance='primary' buttonText='Submit' />
                </Form>
            )}
        </Formik>
    );
};

SendFeedbackForm.propTypes = {
    authToken: PropTypes.string.isRequired,
    setSubmitLoader: PropTypes.func.isRequired,
    data: PropTypes.array,
};

export default SendFeedbackForm;
