import React from 'react';
import { Formik, Form } from 'formik';
import { FORM_FIELDS, getValidationSchema, initialValues } from './constants';
import { CustomTextArea } from 'components/CustomTextArea';
import { EWButton } from 'components/EWButton';
import { CustomMultiSelectDropdown } from 'components/CustomMultiSelectDropdown';
import { dialog } from '@microsoft/teams-js';
import { Caption1 } from '@fluentui/react-components';
import PropTypes from 'prop-types';

const RequestFeedbackForm = ({ setSubmitLoader, data }) => {
    const { users, currentUserId } = data;
    const handleSubmit = (values) => {
        let data = {
            selectedUser: values.selectedUser.map((value) => value.id),
            message: values?.requestMessage,
        };

        setSubmitLoader(true);
        dialog.url.submit(
            { data: data, startedVia: 'EW Shortcuts messaging extension', flagType: 'feedback_requestFeedbackSubmit' },
            process.env.REACT_APP_TEAMS_APP_ID
        );
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={getValidationSchema(currentUserId)}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            <Form>
                <div className='feedback-title'>{FORM_FIELDS.feedbackFormTitle}</div>
                <CustomMultiSelectDropdown
                    {...FORM_FIELDS.selectedUser}
                    isDropdownOptionLoading={users.isFetching}
                    dropdownOption={users.data}
                />
                <CustomTextArea
                    {...FORM_FIELDS.requestMessage}
                    label={
                        <>
                            Request message <Caption1>(Optional)</Caption1>
                        </>
                    }
                />
                <EWButton appearance='primary' buttonText='Submit' />
            </Form>
        </Formik>
    );
};

RequestFeedbackForm.propTypes = {
    setSubmitLoader: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
};

export default RequestFeedbackForm;
